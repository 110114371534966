import { extendTheme } from "@mui/joy/styles";
import {
	DARK_BACKGROUND,
	DEFAULT_TEXT,
	ICON_DOCUMENT,
	PRIMARY,
	SECONDARY,
	SECONDARY_LIGHT
} from "./colors";

declare module "@mui/joy/styles" {
  interface Palette {
    brand: {
      primary: string;
      secondary: string;
			darkBackground: string;
    };
		icon: {
			document: string;
		}
  }
}

declare module "@mui/joy/Button" {
  interface ButtonPropsVariantOverrides {
    nav: true;
  }
}

declare module "@mui/joy/Card" {
  interface CardPropsVariantOverrides {
    paddedPlain: true;
    paddedOutlined: true;
  }
}

declare module "@mui/joy/styles" {
  interface TypographySystemOverrides {
    inputLabel: true;
		inputHelper: true;
  }
}

export const joyTheme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				text: {
					primary: DEFAULT_TEXT
				},
				// Example of new color tokens.
				// We recommend to limit them to 3 levels deep－in this case `palette.brand.primary`.
				brand: {
					primary: PRIMARY,
					secondary: SECONDARY,
					darkBackground: DARK_BACKGROUND
				},
				icon: {
					document: ICON_DOCUMENT
				}
			}
		}
	},
	fontFamily: {
		body: "Open Sans, var(--joy-fontFamily-fallback)",
		display: "Open Sans, var(--joy-fontFamily-fallback)"
	},
	typography: {
		h1: {
			fontWeight: 700
		},
		h2: {
			fontWeight: 700
		},
		h3: {
			fontWeight: 600
		},
		h4: {
			fontWeight: 600
		},
		"body-xs": {
			fontWeight: 400
		},
		"body-sm": {
			fontWeight: 400
		},
		"body-md": {
			fontWeight: 400
		},
		"body-lg": {
			fontWeight: 400
		},
		"title-sm": {
		},
		"title-md": {
		},
		"title-lg": {
		},
		inputLabel: {
			fontFamily: "var(--joy-fontFamily-body)",
			fontWeight: "var(--joy-fontWeight-md)",
			fontSize: "var(--joy-fontSize-lg)",
			lineHeight: "var(--joy-lineHeight-md)",
			color: "var(--joy-palette-text-primary)",
			marginTop: "1.5rem",
			marginBottom: "0.5rem"
		},
		inputHelper: {
			fontFamily: "var(--joy-fontFamily-body)",
			fontWeight: "var(--joy-fontWeight-sm)",
			fontSize: "var(--joy-fontSize-md)",
			lineHeight: "var(--joy-lineHeight-md)",
			color: "var(--joy-palette-text-primary)",
			marginTop: "0.75rem"
			// marginBottom: "0.5rem"
		}
	},
	components: {
		JoyButton: {
			defaultProps: {
				loadingPosition: "end"
			},
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === "nav" && {
						color: theme.colorSchemes.light.palette.common.white,
						paddingY: "1rem",
						paddingX: "2rem",
						background: "transparent",
						"&:hover": {
							backgroundColor: SECONDARY_LIGHT
						}
					})
				})
			}
		},
		JoyCard: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === "paddedPlain" && {
						padding: "2rem",
						width: "auto",
						margin: "1rem"
					}),
					...(ownerState.variant === "paddedOutlined" && {
						padding: "2rem",
						width: "auto",
						margin: "1rem",
						"--variant-borderWidth": "1px",
						border: "var(--variant-borderWidth) solid",
						borderColor: "var(--joy-palette-neutral-outlinedBorder)"
					})
				})
			}
		}
	}
});

// console.log({ joyTheme });
