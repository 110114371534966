import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import dayjs from "dayjs";
import type { IOtpRequestResponseUserInfo, IOtpRequestResponseTokenResponse, IOtpRequestResponse } from "@types";

interface IUserAuthStore {
	userInfo: IOtpRequestResponseUserInfo | null;
	authInfo: IOtpRequestResponseTokenResponse | null;
	removeAuthentication: () => void;
	setLoginDetails: (response: any) => void;
	updateTokens: (response: any) => void;
}

let userAuthStore = (set: any, get: any) => ({
	userInfo: null,
	authInfo: null,
	removeAuthentication: () => {
		set({ authInfo: null });
	},
	setLoginDetails: (response: IOtpRequestResponse) => {
		const { tokenResponse, userInfo } = response;

		const authInfo: IOtpRequestResponseTokenResponse = {
			access_token: tokenResponse.access_token,
			refresh_token: tokenResponse.refresh_token,
			expires_in: tokenResponse.expires_in,

			expires_at: dayjs().add(tokenResponse.expires_in - 100, "seconds").toDate()
		};

		set({ userInfo, authInfo });
	},
	updateTokens: (tokenResponse: IOtpRequestResponseTokenResponse) => {
		// Exit if invalid token response in parameter
		if (!tokenResponse?.access_token || !tokenResponse?.refresh_token) {
			return;
		}

		const { userInfo, authInfo } = get();
		authInfo.access_token = tokenResponse?.access_token;
		authInfo.refresh_token = tokenResponse?.refresh_token;
		authInfo.expires_in = tokenResponse?.expires_in;

		authInfo.expires_at = dayjs().add(tokenResponse?.expires_in - 100, "seconds").toDate();

		set({ userInfo, authInfo });
	}
});

// @ts-ignore
userAuthStore = persist(userAuthStore, { name: "auth", storage: createJSONStorage(() => sessionStorage) });
export const useUserAuthStore = create<IUserAuthStore>(userAuthStore);