import {
	DEV_AUTH_SERVER_URL,
	DEV_CLIENT_URL,
	DEV_SERVER_URL,
	IS_DEV,
	IS_PROD,
	PROD_AUTH_SERVER_URL,
	PROD_CLIENT_URL,
	PROD_SERVER_URL
} from "../constants";

export const getAuthServerUrl = (): string => {
	if (IS_DEV) return DEV_AUTH_SERVER_URL;
	if (IS_PROD) return PROD_AUTH_SERVER_URL;

	return DEV_AUTH_SERVER_URL;
};

export const getServerUrl = (): string => {
	if (IS_DEV) return DEV_SERVER_URL;
	if (IS_PROD) return PROD_SERVER_URL;

	return DEV_SERVER_URL;
};

export const getRedirectUrl = (): string => {
	if (IS_DEV) return DEV_CLIENT_URL;
	if (IS_PROD) return PROD_CLIENT_URL;

	return DEV_CLIENT_URL;
};