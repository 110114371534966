const edenGreen = "#0d3b38";
const genoaGreen = "#1a7459";
const marinerBlue = "#237bde";
const tropazBlue = "#2653a6";
const frenchPassBlue = "#bcdcff";

const burntSiennaRed = "#EC5A5A";
const fuchsiaBlue = "#7256C1";
const scooterBlue = "#30C3CC";
const easternBlue = "#17ACA4";
const coniferGreen = "#94D752";
const orchid = "#D956CC";
const jellyBean = "#2DA5BF";

export const PRIMARY = marinerBlue;
export const PRIMARY_LIGHT = frenchPassBlue;
export const PRIMARY_DARK = tropazBlue;
export const SECONDARY = edenGreen;
export const SECONDARY_LIGHT = genoaGreen;

export const DEFAULT_TEXT = "rgba(0,0,0,0.8)";
export const LIGHT_BACKGROUND = "#F4F8FF";
export const DARK_BACKGROUND = "#576779";

export const ICON_DOCUMENT = fuchsiaBlue;