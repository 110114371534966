import { useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Typography } from "@mui/joy";
import { elevation1 } from "../../theme/shadows";
import { DEFAULT_TEXT, PRIMARY } from "../../theme/colors";
import { isAuthenticated } from "utils";

function handleAccept (): void {
	// thank you
}

function isCookiesAccepted (): boolean {
	if (Cookies.get("CookieConsent") || Cookies.get("CookieConsent-legacy")) {
		return true;
	} else return false;
}

function AuthenticatedCookieBanner () {
	useEffect(() => {
		if (isCookiesAccepted()) {
			handleAccept();
		}
	}, []);

	const buttonStyle = {
		backgroundColor: "white",
		padding: "0.6rem 1.2rem",
		borderRadius: "0.2rem",
		color: PRIMARY,
		border: "1px solid #ced4da",
		fontWeight: 600
	};

	if (isCookiesAccepted()) return <></>;

	return (
		<CookieConsent
			cookieSecurity
			enableDeclineButton
			flipButtons
			onAccept={handleAccept}
			buttonText="Accept All"
			declineButtonText="Reject All"
			style={{
				...elevation1,
				backgroundColor: "white",
				width: "80%",
				alignItems: "center",
				left: "10%",
				borderRadius: "0.5rem",
				marginBottom: "1rem",
				color: DEFAULT_TEXT,
				padding: "0.5rem"
			}}
			buttonStyle={buttonStyle}
			declineButtonStyle={buttonStyle}
		>
			<Typography>
				We use Essential cookies to provide and secure our websites, as well as to analyze the usage
				of our websites, in order to offer you a great user experience. To learn more about our use
				of cookies see our{" "}
				<a href="https://docs.google.com/document/d/11a-cVROTek33Z6A9xh_X2UXxT2sgOozbPZH4DfCfa0I/edit?usp=sharing" style={{ color: PRIMARY, fontWeight: 600 }}>Cookie Policy</a>.
			</Typography>
			<br />
			<Typography>
				Select <b>Accept all</b> to consent to this use or <b>Reject all</b> to decline this use.
			</Typography>
		</CookieConsent>
	);
}

export default function CookieBanner (): JSX.Element {
	return (isAuthenticated()) ? <AuthenticatedCookieBanner /> : <></>;
}