export enum Environment {
	development = "DEV",
	production = "PRODUCTION"
}

export const ClarityConfig = {
	id: "fvcnhq8enz"
};

export const IS_DEBUG = true;

export const CURRENT_ENVIRONMENT = process.env.NEXT_PUBLIC_STAGE;

export const IS_DEV = CURRENT_ENVIRONMENT === Environment.development;
export const IS_PROD = CURRENT_ENVIRONMENT === Environment.production;

// export const DEV_SERVER_URL = "http://localhost:3000";
export const DEV_SERVER_URL = "https://api-patient-dev.careiq.health";
export const PROD_SERVER_URL = "https://api-patient.careiq.health";

// export const DEV_AUTH_SERVER_URL = "http://localhost:5000";
export const DEV_AUTH_SERVER_URL = "https://auth-dev.careiq.health";
export const PROD_AUTH_SERVER_URL = "https://auth.careiq.health";

export const { CIQ_AUTH_CLIENT_ID } = process.env;
export const { CIQ_AUTH_CLIENT_SECRET } = process.env;


//export const DEV_CLIENT_URL = "http://localhost:3001/";
export const DEV_CLIENT_URL = "https://patient-dev.careiq.health/";
export const PROD_CLIENT_URL = "https://patient.careiq.health/";

export const LOGOUT_REDIRECT_URL = "https://www.careiq.health";

export const DEFAULT_COUNTRY_CODE = "GB";

export const publicRoutes = [ "/login", "/login/auth-link", "/login/access-link/verify", "/404", "/general-error", "/maintenance", "/error-expired", "/error-invalid", "/error-unsupported-browser" ];

export const clinicianPatientSharedKey = {
	algorithm: "RS256",
	publicKey: process.env.NEXT_PUBLIC_CLINICIAN_PATIENT_SHARED_JWT_PUBLIC_KEY || ""
};

type LoginMethod = "AUTH_LINK" | "AUTH_CODE";
export const LOGIN_METHOD: LoginMethod = "AUTH_LINK";