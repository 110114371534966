import React from "react";
import * as Sentry from "@sentry/nextjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { legalPolicy } from "@careiq-health/biomarker-utils";
import { Alert, Button, Checkbox, CircularProgress, FormControl, FormHelperText, Grid, Link, Modal, ModalDialog, Typography } from "@mui/joy";

import { ClarityConfig, LOGOUT_REDIRECT_URL } from "../../../constants";
import { createUserConsent, getUserConsent, logout } from "services/authService";
import { isAuthenticated } from "utils";
import { useUserAuthStore } from "../../../store";
import clarityInit from "./clarity";
import type { IUserConsent } from "@types";

export interface IGetConsentResponse {
	data: boolean
	error: { title: string }
}

function ConsentDialog () {
	const logoutMutation = useMutation(() => logout(), {
		onSuccess: () => {
			// Remove stored data
			// https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
			document.cookie.split(";").forEach((c) => {
				document.cookie = c
					.replace(/^ +/, "")
					.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
			});

			window.localStorage.clear();
			window.sessionStorage.clear();

			window.location.assign(LOGOUT_REDIRECT_URL);
		},
		onError: (error: Error) => {
			Sentry.captureMessage(`Error! logoutMutation`);
			Sentry.captureException(error);
		}
	});

	function AuthenticatedConsentDialog () {
		const [ hasAgreedTandC, setHasAgreedTandC ] = React.useState<boolean>(false);
		const [ hasAgreedCookie, setHasAgreedCookie ] = React.useState<boolean>(false);
		const [ hasAgreedPrivacy, setHasAgreedPrivacy ] = React.useState<boolean>(false);
		const [ hasAgreedEighteenPlus, setHasAgreedEighteenPlus ] = React.useState<boolean>(false);
		const [ errorMessage, setErrorMessage ] = React.useState("");

		const { userInfo } = useUserAuthStore();
		const { data: consentData, isLoading: consentIsLoading, isError: consentIsError, error: error } = useQuery<IGetConsentResponse | undefined, IGetConsentResponse>(([ "getConsentDetails" ]), () => getUserConsent(userInfo?.id));
		const consentMutation = useMutation((userConsent: IUserConsent) => createUserConsent(userConsent, userInfo?.id));

		const handleLogout = () => {
			logoutMutation.mutate();
		};

		const handleSubmitConsent = () => {
			if (!hasAgreedTandC || !hasAgreedPrivacy || !hasAgreedCookie || !hasAgreedEighteenPlus) {
				setErrorMessage("Please confirm all of the above to continue.");
				return;
			}

			const userConsent: IUserConsent = {
				termsAndConditions: hasAgreedTandC,
				privacyPolicy: hasAgreedPrivacy,
				ageOver18Consent: hasAgreedEighteenPlus,
				cookiePolicy: hasAgreedCookie
			};

			consentMutation.mutate(userConsent);
		};

		const handleClose = (event: any, reason: string) => {
			if (reason === "backdropClick") {
				return false;
			}

			return false;
		};

		const formControlStyle = {
			width: 400, marginBottom: "1rem", maxWidth: "fit-content"
		};

		if (consentIsLoading) return <CircularProgress variant="soft"/>;
		if (consentData) {
			clarityInit(ClarityConfig.id);
		}

		return (
			<Modal
				open={!consentData?.data && !consentMutation.isSuccess}
				onClose={handleClose}
				disableEscapeKeyDown
			>
				<ModalDialog
					aria-labelledby="consent-modal-dialog-title"
					aria-describedby="consent-modal-dialog-description"
					sx={{ maxWidth: "-webkit-fill-available" }}
				>
					<form>
						{consentIsError && <Alert variant="soft" color="danger" sx={{ marginBottom: "0.5rem" }}>
							<Typography color="danger" fontWeight="md">
								{error?.error?.title || "Oops! Something went wrong. Try again"}
							</Typography>
						</Alert>}
						<Typography component="h2" id="close-modal-title" level="h4" textColor="inherit">
							Legals
						</Typography>
						<Typography id="consent-modal-dialog-description" marginBottom={"1rem"}>
							Please can you confirm all of the following:
						</Typography>
						<FormControl size="sm" sx={formControlStyle}>
							<Checkbox
								checked={!!hasAgreedTandC}
								onChange={() => setHasAgreedTandC(!hasAgreedTandC)}
								label={"I have read and agree to CareIQ's Terms & Conditions"}
							/>
							<FormHelperText>
								<Typography level="body-sm">
									Read our <Link href={legalPolicy.termsAndConditionsPatientPolicy.url}>terms and conditions</Link>.
								</Typography>
							</FormHelperText>
						</FormControl>
						<FormControl size="sm" sx={formControlStyle}>
							<Checkbox
								checked={!!hasAgreedCookie}
								onChange={() => setHasAgreedCookie(!hasAgreedCookie)}
								label={"I have read and agree to CareIQ's Cookie Policy"}
							/>
							<FormHelperText>
								<Typography level="body-sm">
									Read our <Link href={legalPolicy.cookiePolicy.url}>cookie policy</Link>.
								</Typography>
							</FormHelperText>
						</FormControl>
						<FormControl size="sm" sx={formControlStyle}>
							<Checkbox
								checked={!!hasAgreedPrivacy}
								onChange={() => setHasAgreedPrivacy(!hasAgreedPrivacy)}
								label={"I have read and agree to CareIQ's Privacy Policy"}
							/>
							<FormHelperText>
								<Typography level="body-sm">
									Read our <Link href={legalPolicy.privacyPolicy.url}>privacy policy</Link>.
								</Typography>
							</FormHelperText>
						</FormControl>
						<FormControl size="sm" sx={{ ...formControlStyle, marginBottom: "0rem" }}>
							<Checkbox
								checked={!!hasAgreedEighteenPlus}
								onChange={() => setHasAgreedEighteenPlus(!hasAgreedEighteenPlus)}
								label={"I confirm that I am at least 18 years old"}
							/>
						</FormControl>
						{errorMessage && <Typography sx={{ paddingTop: "1.5rem" }} color="danger">{errorMessage}</Typography>}
						<Grid container flexDirection={"row"} marginTop={"1rem"} justifyContent={"flex-end"}>
							<Button
								variant="plain"
								color="neutral"
								onClick={handleLogout}
								disabled={consentMutation.isLoading || consentIsLoading}
								sx={{ marginRight: "1rem" }}
							>
								Disagree
							</Button>
							<Button
								variant="solid"
								onClick={handleSubmitConsent}
								loading={consentMutation.isLoading || consentIsLoading}
								disabled={consentMutation.isLoading || consentIsLoading || !hasAgreedTandC || !hasAgreedPrivacy || !hasAgreedCookie || !hasAgreedEighteenPlus}
							>
								Agree
							</Button>
						</Grid>
					</form>
				</ModalDialog>
			</Modal>
		);
	}

	return (isAuthenticated()) ? <AuthenticatedConsentDialog /> : <></>;
}

export default ConsentDialog;
