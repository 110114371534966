import React from "react";
import { Box, BoxProps } from "@mui/joy";

function Header (props: BoxProps) {
	return (
		<Box
			component="header"
			className="Header"
			{...props}
			sx={[
				{
					p: 1,
					gap: 2,
					bgcolor: "brand.secondary",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					gridColumn: "1 / -1",
					borderBottom: "1px solid",
					borderColor: "divider",
					position: "sticky",
					top: 0,
					zIndex: 1000
				},
				// eslint-disable-next-line react/destructuring-assignment
				...(Array.isArray(props.sx) ? props.sx : [ props.sx ])
			]}
		/>
	);
}

export default {
	Header
};
