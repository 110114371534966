import React from "react";
import { useRouter } from "next/router";

import LoginPage from "../pages/login";
import { publicRoutes } from "../constants";
import { isAuthenticated } from "utils";

type CiqAuth0ProviderProps = {
	children: React.ReactNode
}

export const CiqAuthGuard = (props: CiqAuth0ProviderProps) => {
	const { children } = props;
	const router = useRouter();
	const isAuthenticatedState = isAuthenticated();

	// Allow public routes
	if (publicRoutes.includes(router.pathname)) {
		return <>{children}</>;
	}

	if (!isAuthenticatedState) {
		return <LoginPage/>;
	}

	return <>{children}</>;
};