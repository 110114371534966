import {
	isValidPhoneNumber as verifyPhoneNumber,
	parsePhoneNumber
} from "libphonenumber-js";
import dayjs from "dayjs";
import { DEFAULT_COUNTRY_CODE } from "../constants";
import { useUserAuthStore } from "../store";
import { logout, refreshAccessToken } from "services/authService";

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
	return verifyPhoneNumber(phoneNumber, DEFAULT_COUNTRY_CODE) === true;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
	const number = parsePhoneNumber(phoneNumber, DEFAULT_COUNTRY_CODE);

	// To international format
	return number.format("E.164");
};


export const toTitleCase = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const isAgeOlderThan = (dateOfBirth: Date, minAge = 18) => {
	const today = dayjs();
	const age = today.diff(dateOfBirth, "year");

	if (age >= minAge) return true;
	return false;
};

export const isAuthenticated = () => {
	const { authInfo } = useUserAuthStore.getState();

	if (!authInfo?.access_token) {
		return false;
	}

	if (dayjs().isAfter(dayjs(authInfo?.expires_at))) {
		const { updateTokens, removeAuthentication } = useUserAuthStore.getState();
		return refreshAccessToken(authInfo?.refresh_token).then((response) => {
			if (response === undefined) {
				logout();
				removeAuthentication();
				return false;
			}

			updateTokens(response?.data?.tokenResponse);
			return true;
		});
	}

	return true;
};