import React from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Grid, Input, Typography, Button, Divider, Card, FormControl } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faLock, faMagicWandSparkles } from "@fortawesome/pro-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import { isValidEmail } from "@careiq-health/utils";

import { processCareIQLogin, processCareIQLoginOtp, processClinicSelection } from "services/authService";
import { centreOfPageAlt } from "../../styles/shared";
import { useUserAuthStore } from "../../store";
import { LOGIN_METHOD } from "../../constants";
import { SECONDARY, SECONDARY_LIGHT } from "theme/colors";
import type { IOtpRequestResponseModel, ITokenRefreshRequestResponseModel } from "@types";

interface IPatientSelectionRequest {
	organisationId: string;
	patientId: string;
}

function LoginLayout () {
	const router = useRouter();
	const { query } = router;
	// In the router query when the user registration flow directs the
	// user to login page and prefills the email address
	const { emailAddress } = router.query;

	const [ localEmailAddress, setLocalEmailAddress ] = React.useState(typeof emailAddress === "string" ? emailAddress : "");
	const [ otpCode, setOtpCode ] = React.useState("");
	const [ isOtpBlockVisible, setIsOtpBlockVisible ] = React.useState(false);
	const [ isWaitingForAuthLink, setIsWaitingForAuthLink ] = React.useState(false);
	const [ errorMessage, setErrorMessage ] = React.useState(query.errorMessage || "");

	const { setLoginDetails, updateTokens } = useUserAuthStore();

	React.useEffect(() => {
		window.localStorage.clear();
		window.sessionStorage.clear();
	}, []);

	const processLoginMutation = useMutation(() => processCareIQLogin(localEmailAddress), {
		onSuccess: (response: {data: {message: string}}) => {
			if (!response?.data?.message) {
				setErrorMessage("Oops! Something went wrong");
				return;
			}

			if (LOGIN_METHOD === "AUTH_LINK") {
				setIsWaitingForAuthLink(true);
			}
			if (LOGIN_METHOD === "AUTH_CODE") {
				setIsOtpBlockVisible(true);
			}

			setErrorMessage("");
		},
		onError: (error: {title: string}) => {
			setErrorMessage(error?.title || "Oops! Something went wrong");
		}
	});

	const processClinicSelectionMutation = useMutation(({ organisationId, patientId }: IPatientSelectionRequest) => processClinicSelection(organisationId, patientId), {
		onSuccess: (response: ITokenRefreshRequestResponseModel) => {
			updateTokens(response?.data?.tokenResponse);
		},
		onError: (error: {title: string}) => {
			setErrorMessage(error?.title || "Oops! Something went wrong");
		}
	});

	const submitOtpRequest = useMutation(() => processCareIQLoginOtp(localEmailAddress, +otpCode), {
		onSuccess: (response: IOtpRequestResponseModel) => {
			if (!response?.data?.userInfo?.type) {
				setErrorMessage("Oops! Something went wrong. Refresh the page and try again.");
				return;
			}
			if (response?.data?.userInfo?.type !== "Patient") {
				setErrorMessage("Oops! This login is only for patients.");
				return;
			}

			setLoginDetails(response?.data);
			const patientSelectionRequest: IPatientSelectionRequest = {
				organisationId: response?.data?.userInfo?.userPatients?.[0].organisationId,
				patientId: response?.data?.userInfo?.userPatients?.[0].patientId
			};
			processClinicSelectionMutation.mutate(patientSelectionRequest);

			router.push({
				pathname: "/"
			});
		},
		onError: (error: {title: string}) => {
			setErrorMessage(error?.title || "Oops! Something went wrong");
		}
	});

	const handleSubmitButtonClick = () => {
		if (localEmailAddress && isValidEmail(localEmailAddress)) {
			if (LOGIN_METHOD === "AUTH_LINK") {
				// Issue the auth link
				processLoginMutation.mutate();
			}

			if (LOGIN_METHOD === "AUTH_CODE") {
				isOtpBlockVisible ? submitOtpRequest.mutate() : processLoginMutation.mutate();
			}
		} else {
			setErrorMessage("Invalid email address entered");
		}
	};

	const UserLoginCommunicationMessage = (props: { message: string }) => {
		const { message } = props;

		const iconStyle = {
			opacity: 0.8,
			fontSize: "1rem",
			marginRight: "1rem",
			color: "white"
		};

		return (
			<Grid
				container
				flexDirection="row"
				alignItems="center"
				justifyContent={"center"}
				margin="1rem"
				padding="1rem"
				marginTop="2rem"
				borderRadius="4px"
				maxWidth={"fit-content"}
				flexWrap="initial"
				sx={{
					backgroundColor: "rgba(0,0,0,0.25)",
					opacity: 0.8
				}}
			>
				<FontAwesomeIcon icon={faLock} style={iconStyle}/>
				<Typography textColor="white">
					{message}
				</Typography>
			</Grid>
		);
	};


	return (
		<Grid sx={ isMobile ? {
			overflow: "scroll",
			paddingTop: "2rem",
			paddingBottom: "2rem",
			backgroundColor: SECONDARY
		} : {
			...centreOfPageAlt,
			backgroundColor: SECONDARY
		}}>
			<Card variant="paddedPlain">
				<Image alt="CareIQ logo" src={"/careiq-logo-colour.svg"} width={300} height={30} priority />
				<Typography level="h4" marginY="2rem" textAlign={"center"}>Login to CareIQ for Patients</Typography>
				<Divider />
				{!(isOtpBlockVisible || isWaitingForAuthLink) &&
					<>
						<Typography level="inputLabel">
							Email
						</Typography>
						<FormControl id="email" size="lg" required>
							<Input
								placeholder="example@email.com"
								variant="outlined"
								autoFocus
								type="email"
								autoComplete="email"
								autoCapitalize="none"
								onChange={(event) => setLocalEmailAddress(event.target.value)}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.preventDefault();
										handleSubmitButtonClick();
									}
								}}
								disabled={isWaitingForAuthLink || isOtpBlockVisible || processLoginMutation.isLoading}
								value={localEmailAddress} />
						</FormControl>
					</>
				}
				{isOtpBlockVisible &&
					<>
						<Typography level="inputLabel">
							Auth Code
						</Typography>
						<Typography textAlign={"center"} marginBottom={"-0.5rem"} sx={{ opacity: 0.8 }}>
							We&rsquo;ve emailed you a 6-character code.
						</Typography>
						<Typography textAlign={"center"} sx={{ opacity: 0.8 }}>
							The code expires shortly, so please enter it soon.
						</Typography>
						<FormControl id="otp" size="lg" required>
							<Input
								autoFocus
								variant="outlined"
								autoComplete="off"
								onChange={(event) => setOtpCode(event.target.value)}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.preventDefault();
										handleSubmitButtonClick();
									}
								}}
								value={otpCode} />

						</FormControl>
					</>
				}
				{isWaitingForAuthLink && (LOGIN_METHOD === "AUTH_LINK") &&
					<>
						<Typography level="inputLabel" textAlign={"center"}>
							Magic Link
						</Typography>
						<Grid justifyContent="center" padding={"1.5rem"} marginBottom={"0.5rem"} sx={{ display: "flex" }}>
							<FontAwesomeIcon icon={faMagicWandSparkles} style={{ fontSize: "2rem", color: SECONDARY_LIGHT }}/>
							<FontAwesomeIcon icon={faEnvelopeOpen} style={{ fontSize: "4rem", color: SECONDARY_LIGHT }}/>
						</Grid>
						<Typography textAlign={"center"} marginBottom={"-0.5rem"} sx={{ opacity: 0.8 }}>
							We&rsquo;ve emailed you a link to login
						</Typography>
						<Typography textAlign={"center"} sx={{ opacity: 0.8 }}>
							The link expires shortly, so please click it soon
						</Typography>
					</>
				}
				{errorMessage && <Typography sx={{ paddingTop: "1rem", maxWidth: "20rem" }} color="danger">{errorMessage}</Typography>}
				{!isWaitingForAuthLink &&
					<Button
						size="lg"
						color="primary"
						onClick={handleSubmitButtonClick}
						disabled={!localEmailAddress || (isOtpBlockVisible && !otpCode)}
						loading={processLoginMutation.isLoading || submitOtpRequest.isLoading}
						sx={{
							marginTop: "1rem"
						}}
					>
						{"Sign in"}
					</Button>
				}
				{!isWaitingForAuthLink && (LOGIN_METHOD === "AUTH_LINK") &&
					<Grid container flexDirection={"column"} alignItems={"center"}>
						<Typography level="body-sm" marginTop={"2rem"} sx={{ opacity: 0.8 }}>
							CareIQ Patients is currently invite-only
						</Typography>
						<Typography level="body-sm" sx={{ opacity: 0.8 }}>
							Only available at participating GPs
						</Typography>
					</Grid>
				}
			</Card>
			{!isOtpBlockVisible && (LOGIN_METHOD === "AUTH_CODE") &&
				<UserLoginCommunicationMessage message="We&rsquo;ll email you an auth code for a password-free sign in." />
			}
			{!isWaitingForAuthLink && (LOGIN_METHOD === "AUTH_LINK") &&
				<UserLoginCommunicationMessage message="We&rsquo;ll email you an magic link for a password-free sign in." />
			}
			{(isOtpBlockVisible || isWaitingForAuthLink) &&
				<Typography textColor="white" marginTop="2rem" sx={{ opacity: 0.8 }}>
					Can&rsquo;t find your email? Check your spam folder!
				</Typography>
			}
		</Grid>
	);
}

export default LoginLayout;