import { Open_Sans } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import NextNProgress from "nextjs-progressbar";
import { AppProps } from "next/app";
import { CssVarsProvider } from "@mui/joy/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { matchesUA } from "browserslist-useragent";

// Required for nextjs to prevent icon loading issues
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import NavBar from "components/navBar";
import GeneralErrorPage from "./general-error";
import { PRIMARY } from "theme/colors";
import { joyTheme } from "theme/joyTheme";
import "../styles/globals.css";
import { CiqAuthGuard } from "services/authGuard";
import CookieBanner from "components/cookieBanner";
import ConsentDialog from "components/dialog/consentDialog";
import { useUserAuthStore } from "store";
import { useEffect, useRef } from "react";
import { logout } from "services/authService";

const IS_HOLDING_STATE = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true";

const PAGE_TITLE = "CareIQ for Patients - Built for the NHS";
const PAGE_DESCRIPTION = "Login to CareIQ and engage with your health. Through our integrated platform, patients, NHS professionals and more can manage health online.";

const openSans = Open_Sans({
	subsets: [ "latin" ],
	weight: [ "400", "500", "600", "700", "800" ]
});

export const queryClient = new QueryClient();

// polyfill support on array.at for older browsers
// based on - https://dev.to/lexlohr/comment/1lcbl
if (![].at) {
	Array.prototype.at = function (pos) {
		return this.slice(pos, pos + 1)[0];
	};
}

function App ({ Component, pageProps }: AppProps) {
	const router = useRouter();
	const { authInfo, updateTokens, removeAuthentication } = useUserAuthStore();

	const workerRef = useRef<Worker | null>(null);

	useEffect(() => {
		if (authInfo?.refresh_token && "Worker" in window) {
			workerRef.current = new Worker(new URL("../tokenRefreshWorker.ts", import.meta.url));

			workerRef.current.postMessage(`start,${authInfo?.refresh_token},${authInfo?.access_token}`);
			workerRef.current.addEventListener("message", (event) => {

				if (!event?.data?.data?.tokenResponse?.refresh_token) {
					logout();
					removeAuthentication();
				}

				const tokenResponse = event.data?.data?.tokenResponse;

				if (tokenResponse && authInfo?.refresh_token) {
					updateTokens(tokenResponse);
				}
			});
		}

		return () => {
			if (workerRef?.current) {
				workerRef.current.postMessage("stop");
			}
		};
	}, [ authInfo?.access_token, authInfo?.refresh_token, updateTokens ]);

	useEffect(() => {
		const { userAgent } = window.navigator;
		const isUnsupportedBrowser = matchesUA(userAgent, {
			browsers: [
				// "defaults",
				// "maintained node versions",
				// "not dead",
				// "not op_mini all",
				// "chrome 115", // adding manually since it's not updated onto browser list package yet
				// "chrome 116", // adding manually since it's not updated onto browser list package yet
				// "chrome 117", // adding manually since it's not updated onto browser list package yet
				"< 0.5%",
				"node < 16",
				"dead",
				"UCAndroid >= 15.5",
				"op_mini all",
				"not iOS >= 16",
				"not chrome >= 115",
				"not Samsung >= 20" // allow samsung internet v21 and over
			]
		});

		if (isUnsupportedBrowser) {
			router.push({
				pathname: "/error-unsupported-browser"
			});
		}
	}, []);

	return (
		<main className={openSans.className}>
			<Head>
				<title>CareIQ Patients - Built for the NHS</title>
				<link rel="shortcut icon" href={"mstile-70x70.png"}/>
				<meta name="viewport" content="initial-scale=1, width=device-width" />
				<meta name="description" content={PAGE_DESCRIPTION} />
				<meta property="og:title" content={PAGE_TITLE} key="title" />
				<meta property="og:description" content={PAGE_DESCRIPTION} key="description" />
				<meta property="og:image" content={"open-graph-image.jpg"} key="og-image" />
				<meta property="og:site_name" content="CareIQ" key="og-site" />

				<meta name="twitter:title" content={PAGE_TITLE} key="tw-title"/>
				<meta name="twitter:description" content={PAGE_DESCRIPTION} key="tw-desc"/>
				<meta name="twitter:image" content={"open-graph-image.jpg"} key="tw-image"/>
				<meta name="twitter:card" content={"open-graph-image.jpg"} key="tw-card"/>
			</Head>
			<CssVarsProvider theme={joyTheme}>
				<Sentry.ErrorBoundary fallback={<GeneralErrorPage />} showDialog>
					<QueryClientProvider client={queryClient}>
						<NextNProgress color={PRIMARY} height={4} showOnShallow={true} options={{ showSpinner: false }} />
						{!IS_HOLDING_STATE && <NavBar />}
						<CiqAuthGuard>
							<Component {...pageProps} />
						</CiqAuthGuard>
						{!IS_HOLDING_STATE && <CookieBanner />}
						{!IS_HOLDING_STATE && <ConsentDialog />}
					</QueryClientProvider>
				</Sentry.ErrorBoundary>
			</CssVarsProvider>
		</main>
	);
}

export default App;