import axios from "axios";
import dayjs from "dayjs";
import { getAuthServerUrl } from "../config";
import { useUserAuthStore } from "../store";
import { logout, refreshAccessToken } from "./authService";

// For public endpoints
export const publicAuthApiClient = axios.create({
	baseURL: `${getAuthServerUrl()}`,
	timeout: 5000 // Note: Might cause unexpected errors when running with debug breakpoints.
});

publicAuthApiClient.defaults.headers.common["Content-Type"] = "application/json";

publicAuthApiClient.interceptors.response.use((response) => {
	return response;
},
async (error) => {
	const originalRequest = error.config;

	// https://stackoverflow.com/a/51599698
	if ((error?.response?.status !== 200 || error?.response?.status !== 201) && !originalRequest._retry) {

		if (error?.config?.url?.includes("refresh_token")) {
			return error?.response;
		}

		originalRequest._retry = true;
		throw error?.response?.data?.data?.errors?.[0];
	}

	return Promise.reject(error);
});



// For private endpoints
export const protectedAuthApiClient = axios.create({
	baseURL: `${getAuthServerUrl()}`
	// withCredentials: true
});
protectedAuthApiClient.defaults.headers.common["Content-Type"] = "application/json";

protectedAuthApiClient.interceptors.request.use(async (config) => {
	const { authInfo, updateTokens, removeAuthentication } = useUserAuthStore.getState();
	let response;

	// Refresh token if expired
	if (authInfo?.refresh_token && authInfo?.expires_at && dayjs().isAfter(dayjs(authInfo.expires_at))) {
		response = await refreshAccessToken(authInfo.refresh_token);

		if (response === undefined) {
			logout();
			removeAuthentication();
		}

		updateTokens(response?.data?.tokenResponse);
	}
	const token = response?.data?.tokenResponse?.access_token || authInfo?.access_token;

	// @ts-ignore
	config.headers["Authorization"] = `Bearer ${token}`;

	return config;
}, (error) => Promise.reject(error));

protectedAuthApiClient.interceptors.response.use((response) => {
	return response;
},
async (error) => {
	const { authInfo, updateTokens, removeAuthentication } = useUserAuthStore.getState();
	const originalRequest = error.config;

	if (error?.response?.status === 401) {
		removeAuthentication();
	}

	// https://stackoverflow.com/a/51599698
	if ((error?.response?.status !== 200 || error?.response?.status !== 201) && !originalRequest._retry) {
		let response;
		originalRequest._retry = true;

		if (authInfo?.refresh_token) {
			response = await refreshAccessToken(authInfo?.refresh_token);

			if (response === undefined) {
				logout();
				removeAuthentication();
			}

			updateTokens(response?.data?.tokenResponse);
		}

		throw error?.response?.data?.data?.errors?.[0];
	}
	return Promise.reject(error);
});