import React from "react";
import Image from "next/image";
import Link from "next/link";
import * as Sentry from "@sentry/nextjs";

import { Grid, Typography, Button, Menu, MenuItem, Avatar, Box } from "@mui/joy";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faUser } from "@fortawesome/pro-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";

import { useUserAuthStore } from "store";
import Layout from "../layout";
import { LOGOUT_REDIRECT_URL } from "../../constants";
import { logout } from "services/authService";
import { centreOfPage } from "styles/shared";
import { isAuthenticated } from "utils";

function AuthenticatedNavBar () {
	const { userInfo } = useUserAuthStore();
	const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		// hide menu button if anchorEl already set
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logoutMutation = useMutation(() => logout(), {
		onSuccess: () => {
			// Remove stored data
			// https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
			document.cookie.split(";").forEach((c) => {
				document.cookie = c
					.replace(/^ +/, "")
					.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
			});

			window.localStorage.clear();
			window.sessionStorage.clear();

			window.location.assign(LOGOUT_REDIRECT_URL);
		},
		onError: (error: Error) => {
			Sentry.captureMessage(`Error! logoutMutation`);
			Sentry.captureException(error);
		}
	});

	const handleLogout = () => {
		logoutMutation.mutate();
		handleClose();
	};

	return (
		<Layout.Header>
			<Grid container justifyContent="space-between" alignItems="center" width={"100%"} marginRight="1rem">
				<Grid>
					<Link href="/" passHref>
						<Button variant="nav" sx={{ marginRight: "0rem" }}>
							<Grid flexDirection={"row"} display={"inline-flex"} alignItems="center">
								<Image alt="CareIQ logo" src={"/careiq-logo-white.svg"} width={75} height={20} />
								<Box borderRight={"white 1px solid"} height="2rem" marginX={"0.75rem"} sx={{ opacity: "0.2" }} />
								<Typography level="body-sm" textColor={"white"}>Patient</Typography>
							</Grid>
						</Button>
					</Link>
					{/* <Link href="/qof" passHref>
							<Button variant="nav" sx={router.pathname === "/qof" ? activeNavButtonStyle : navButtonStyle}>
								<Typography variant="h6">QOF</Typography>
							</Button>
						</Link>
						<Link href="/des" passHref>
							<Button variant="nav" sx={router.pathname === "/des" ? activeNavButtonStyle : navButtonStyle}>
								<Typography variant="h6">DES</Typography>
							</Button>
						</Link>
						<Link href="/cancer" passHref>
							<Button variant="nav" sx={router.pathname === "/cancer" ? activeNavButtonStyle : navButtonStyle}>
								<Typography variant="h6">Cancer (BETA)</Typography>
							</Button>
						</Link>
						<Link href="/health-and-wellbeing" passHref>
							<Button variant="nav" sx={router.pathname === "/health-and-wellbeing" ? activeNavButtonStyle : navButtonStyle}>
								<Typography variant="h6">Health & Wellbeing (BETA)</Typography>
							</Button>
						</Link> */}
				</Grid>
				<Grid>
					<Grid container alignItems="center">
						<Grid>
							<Grid container alignItems="center">
								<Button
									variant="nav"
									id="menu-button"
									aria-label="account of current user"
									aria-controls={open ? "menu-button" : undefined}
									aria-expanded={open ? "true" : undefined}
									aria-haspopup="true"
									onClick={handleMenuClick}
									endDecorator={
										<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: "1.2rem",  paddingLeft: "0.2rem" }}/>
									}
								>
									<Avatar size="sm" sx={{ backgroundColor: "lightgrey" }} alt="user icon">
										<FontAwesomeIcon icon={faUser} style={{ fontSize: "1rem" }}/>
									</Avatar>
									<Typography level="body-sm" textColor={"white"} noWrap maxWidth={isMobile ? "3rem" : "6rem"} sx={{ paddingLeft: "1rem" }}>{userInfo?.givenName || "..."}</Typography>
								</Button>
								<Menu
									id="menu-user"
									anchorEl={anchorEl}
									aria-labelledby="basic-demo-button"
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<MenuItem onClick={handleLogout}>Logout</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Layout.Header>
	);
}

export default function NavBar () {
	if (!isAuthenticated()) return <Grid sx={centreOfPage}><Typography></Typography></Grid>;

	return <AuthenticatedNavBar />;
}