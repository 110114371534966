import { SxProps } from "@mui/joy/styles/types";

export const centreOfPage: SxProps = {
	position: "fixed",
	top: "50%",
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	width: "100%"
};

export const centreOfPageAlt: SxProps = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	width: "100%",
	minHeight: "100vh",
	height: "100%"
};