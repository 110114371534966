import { NextPage } from "next";
import { Grid, Typography } from "@mui/joy";
import { centreOfPageAlt } from "../styles/shared";

const GeneralErrorPage: NextPage = () => {
	return (
		<Grid container padding={"2rem"} sx={centreOfPageAlt}>
			<Typography level="h1" sx={{ marginBottom: "1rem" }}>Oops. It looks like something&apos;s gone wrong.</Typography>
			<Typography level="h2">Our engineers are working to solve this issue as soon as we can.</Typography>
			<Typography level="h2" sx={{ marginBottom: "2rem" }}>Follow the status of this issue live on our Twitter</Typography>
			<Typography level="h3">For any urgent queries contact our support team at support@careiq.health</Typography>
		</Grid>
	);
};

export default GeneralErrorPage;
