import * as Sentry from "@sentry/nextjs";
import { protectedAuthApiClient, publicAuthApiClient } from "./authApiClient";
import { useUserAuthStore } from "../store";
import { IGetConsentResponse } from "components/dialog/consentDialog";
import { legalPolicy } from "@careiq-health/biomarker-utils";
import { PrismaTenantJsonTypes } from "@careiq-health/core-types";
import type { IUserConsent } from "@types";

export const logout = async (): Promise<any | undefined> => {
	try {
		return protectedAuthApiClient.post(`/api/v1/auth/logout`);
	} catch (error) {
		console.error(`${error} in logout()`);
		Sentry.captureMessage(`Error! logout`);
		Sentry.captureException(error);
	}
};

export const refreshAccessToken = async (refreshToken: string, accessToken?: string): Promise<any | undefined> => {
	const { authInfo } = useUserAuthStore.getState();

	try {
		return fetch("/api/token/refresh", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken || authInfo?.access_token}`
			},
			mode: "same-origin",
			body: JSON.stringify({
				refreshToken
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in refreshAccessToken()`);
		Sentry.captureMessage(`Error! refreshAccessToken`);
		Sentry.captureException(error);
		logout();
	}
};

export const processCareIQLogin = async (emailAddress: string): Promise<any | undefined> => {
	const { authInfo } = useUserAuthStore.getState();

	try {
		return fetch("/api/login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${authInfo?.access_token}`
			},
			mode: "same-origin",
			body: JSON.stringify({
				emailAddress
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in processCareIQLogin()`);
		Sentry.captureMessage(`Error! processCareIQLogin`);
		Sentry.captureException(error);
	}
};

export const processClinicSelection = async (organisationId: string, patientId: string): Promise<any | undefined> => {
	const { authInfo } = useUserAuthStore.getState();

	try {
		return fetch("/api/token/connect", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${authInfo?.access_token}`
			},
			mode: "same-origin",
			body: JSON.stringify({
				organisationId,
				patientId
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in refreshAccessToken()`);
		Sentry.captureMessage(`Error! refreshAccessToken`);
		Sentry.captureException(error);
	}
	try {
		return publicAuthApiClient.post(`/api/v1/auth/token/connect`, { organisationId });
	} catch (error) {
		console.error(`${error} in processClinicSelection()`);
		Sentry.captureMessage(`Error! processClinicSelection`);
		Sentry.captureException(error);
	}
};

export const processCareIQLoginOtp = async (emailAddress: string, otpCode: number): Promise<any | undefined> => {
	try {
		return fetch("/api/login/otp", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			mode: "same-origin",
			body: JSON.stringify({
				emailAddress,
				otpCode
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in processCareIQLoginOtp()`);
		Sentry.captureMessage(`Error! processCareIQLoginOtp`);
		Sentry.captureException(error);
	}
};

export const processCareIQAuthLinkLoginToken = async (loginToken: string): Promise<any | undefined> => {
	try {
		return fetch(`/api/login/auth-link?token=${loginToken}`, {
			method: "GET",
			mode: "same-origin"
		}).then((res) => {
			if (res.status === 401) {
				throw new Error("Unauthorized");
			}
			return res.json();
		});
	} catch (error) {
		console.error(`${error} in processCareIQAuthLinkLoginToken()`);
		Sentry.captureMessage(`Error! processCareIQAuthLinkLoginToken`);
		Sentry.captureException(error);
	}
};

export const validateUser = async (userId: string, mobileNumber: string, dateOfBirth: string): Promise<any | undefined> => {
	try {
		return fetch("/api/user/validate", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			mode: "same-origin",
			body: JSON.stringify({
				userId,
				mobileNumber,
				dateOfBirth
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in validateUser()`);
		Sentry.captureMessage(`Error! validateUser`);
		Sentry.captureException(error);
	}
};

export const updateUser = async (userId: string, emailAddress: string): Promise<any | undefined> => {
	try {
		return fetch("/api/user", {
			method: "PATCH",
			headers: { "Content-Type": "application/json" },
			mode: "same-origin",
			body: JSON.stringify({
				userId,
				emailAddress
			})
		}).then((res) => res.json());
	} catch (error) {
		console.error(`${error} in updateUser()`);
		Sentry.captureMessage(`Error! validateUser`);
		Sentry.captureException(error);
	}
};

const generateUserConsentRequest = (userConsent: IUserConsent) => {
	const details: PrismaTenantJsonTypes.IConsentDetails = [];

	if (userConsent.termsAndConditions) {
		const termsAndConditions: PrismaTenantJsonTypes.IConsentDetailItem = {
			consentSubject: legalPolicy.termsAndConditionsPatientPolicy.name,
			version: legalPolicy.termsAndConditionsPatientPolicy.latestVersion,
			hasConsented: userConsent.termsAndConditions
		};
		details.push(termsAndConditions);
	}

	if (userConsent.privacyPolicy) {
		const privacyPolicy: PrismaTenantJsonTypes.IConsentDetailItem = {
			consentSubject: legalPolicy.privacyPolicy.name,
			version: legalPolicy.privacyPolicy.latestVersion,
			hasConsented: userConsent.privacyPolicy
		};
		details.push(privacyPolicy);
	}

	if (userConsent.ageOver18Consent) {
		const ageOver18Consent: PrismaTenantJsonTypes.IConsentDetailItem = {
			consentSubject: legalPolicy.agedEighteenOrOverPolicy.name,
			version: legalPolicy.agedEighteenOrOverPolicy.latestVersion,
			hasConsented: userConsent.ageOver18Consent
		};
		details.push(ageOver18Consent);
	}

	if (userConsent.cookiePolicy) {
		const privacyPolicy: PrismaTenantJsonTypes.IConsentDetailItem = {
			consentSubject: legalPolicy.cookiePolicy.name,
			version: legalPolicy.cookiePolicy.latestVersion,
			hasConsented: userConsent.cookiePolicy
		};
		details.push(privacyPolicy);
	}

	return details;
};

export const createUserConsent = async (userConsent: IUserConsent, userId?: string): Promise<any | undefined> => {
	try {
		const details = generateUserConsentRequest(userConsent);
		return protectedAuthApiClient.post(`/api/v1/users/${userId}/consent`, {
			details
		});
	} catch (error) {
		Sentry.captureMessage(`Error! createUserConsent`);
		Sentry.captureException(error);
	}
};

export const getUserConsent = async (userId?: string): Promise<IGetConsentResponse | undefined> => {

	try {
		return protectedAuthApiClient.get(`/api/v1/users/${userId}/consent`).then((response) => response.data);
	} catch (error) {
		Sentry.captureMessage(`Error! getUserConsent`);
		Sentry.captureException(error);
	}

	return undefined;
};